
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Hidden,
  SvgIcon,
  InputBase,
  Box,
  Typography,
  Button,
  Container,
  Paper,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import ConfirmationDialog from "src/component/Confirmation";
import { Dialog } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { alpha } from "@material-ui/core/styles";
import TopBarData from "./TopBarData";
import { useHistory } from "react-router-dom";
import {getProfileData} from "../../../services/profile"
import RightSideDrawer from "./RIghtSideDrawer";
import { IoNotificationsOutline } from "react-icons/io5";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ethers } from "ethers";
const Data = [
  {
    id: "1",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id mauris tempus, dictum sit luctus condimentum. Amet sed amet, eget ullamcorper",
    text1: "23 August 2022 , 08:00 PM",
  },
  {
    id: "2",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id mauris tempus, dictum sit luctus condimentum. Amet sed amet, eget ullamcorper",
    text1: "23 August 2022 , 08:00 PM",
  },
  {
    id: "3",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id mauris tempus, dictum sit luctus condimentum. Amet sed amet, eget ullamcorper",
    text1: "23 August 2022 , 08:00 PM",
  },
  {
    id: "4",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id mauris tempus, dictum sit luctus condimentum. Amet sed amet, eget ullamcorper",
    text1: "23 August 2022 , 08:00 PM",
  },
  {
    id: "5",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id mauris tempus, dictum sit luctus condimentum. Amet sed amet, eget ullamcorper",
    text1: "23 August 2022 , 08:00 PM",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    background: "",
    float: "right",
    outline: "none",
  },
  desktopDrawer1: {
    background: " #263944",
    position: "absolute",
    right: 73,
    top: 0,
    width: "240px !important",
    padding: "12px",
    color: "#000",
    // background: theme.palette.primary.main,
  },
  toolbar: {
    padding: "0 10px",
    float: "right",
    width: "calc(100% - 256px)",
    right: 0,
    display: "flex",
    justifyContent: "space-between",

    position: "absolute",
    top: -2,
    padding: 0,
    background: `linear-gradient(90.27deg, #263943 -0.76%, #214158 100%)`,
    "@media (max-width: 1279px)": {
      width: "100%",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    border: "1px solid #FFFFFF",
    borderRadius: "10px",
    width: "400px !important",
    opacity: "0.9",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  blueButton: {
    width: "176px",
    height: "36px",
    background: "#00A4EF",
    borderRadius: "4px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "130%",
    color: "#FFFFFF",
    border: "3px solid rgba(0, 161, 255, 1)",
    "&:hover": {
      background: "#244d61",
      color: "#fff",
      border: "3px solid #00AFFF",
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  FiterBox: {
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  icon: {
    width: "16px",
    color: "#ffffff",
    padding: "20px 20px",
    cursor: "pointer",
  },
  input: {
    borderRadius: "7px",
    background: "#fff",
  },
  sensorimg: {
    padding: "10px",
  },
  modal: {
    marginTop: "74px",
    // marginLeft:"100%",
    padding: "15px",
    background: "#FFFFFF",
    border: "1px solid #D9D9D9",
    width: "100%",
    maxWidth: "480px",
    height: "358px",
    background: "#FFFFFF",
    borderRadius: "8px",
  },
  typo2: {
    textAlign: "right",
    //  padding:'0px 0px 0px 347px',
    fontFamily: "Inter",
    fontStyle: " normal",
    fontWeight: "500",
    fontSize: "8px",
    lineHeight: "10px",
    color: "#7D7D7D",
  },
  Horizontal: {
    border: "1px solid #EAEBED",
    height: "37px",
  },
  btnmodal: {
    alignItems: "right",
    width: "102px",
    height: "24px",
    background: "#F2542D",
    borderRadius: "2px",
    fontFamily: "Roboto",
    fontstyle: "normal",
    fontWeight: " 500",
    fontSize: " 10px",
    lineHeight: "14px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#fff",
      color: "#F2542D",
      border: " 3px solid #F2542D",
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  const [rightBar, setRightBar] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [metaData, setmetaData] = useState({
    address: "",
    Balance: null,
    connected: false,
  });

  const [connected, setconnected] = useState(false);
  const [state, setState] = React.useState({
    Deposite: "",
    name: "",
  });
  const handleOpenNoti = () => setOpen(true);
  const handleCloseNoti = () => setOpen(false);
  const [id, setId] = useState([]);
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const getbalance = (address) => {
    window.ethereum
      .request({
        method: "eth_getBalance",
        params: [address, "latest"],
      })
      .then((balance) => {
        setmetaData({
          address: address,
          Balance: ethers.utils.formatEther(balance),
        });
      });
  };
  const [profile, setProfile] = useState()
    useEffect(() => {
      getData();
    }, []);
    console.log(profile,"ggggggggggggg")
  const getData = async () => {
    try {
      const token = localStorage.getItem("token");
      const res = await getProfileData(token)
      if (res.data.message === "Get Data Successfully") {
        const profileData = res?.data?.user;
        setProfile(profileData);
      }

      return res.data.result;
    } catch (error) {
      console.log("Error", error);
    }
  };
  const accountChangeHandler = (account) => {
    setmetaData({
      address: account,
      connected: true,
    });

    getbalance(account);
  };

  const BtnHandler = async() => {
    if (window.ethereum) {
      const res = await window.ethereum.request({ method: "eth_requestAccounts" });

      if (res) {
        accountChangeHandler(res[0]);
        localStorage.setItem("walletConneted", true);
        localStorage.setItem("userAddress",res[0])
      }
    } else {
      alert("install metamask extension!!");
    }
  };
  console.log(metaData,"meta")
  useEffect(() => {
    const userId = localStorage.getItem("walletConneted");
    if (userId) {
      setId(userId);
    } else {
      setId(false);
    }
  }, []);
  console.log(id, "userId");
  const name = localStorage.getItem("name");
  const address = localStorage.getItem("userAddress")
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        className="walletbox"
      >
        <Box className={classes.ProfileTitle}>
          <Typography variant="h6" align="left">
            {profile?.firstname ? profile?.firstname : "user"}
          </Typography>
          <Typography variant="caption" style={{ color: "#000" }} align="left">
            0xx25dswdw...21dez
          </Typography>
        </Box>
        <div
          style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.11)", height: 10 }}
        ></div>
        <Box pt={1}>
          return (
          <Button
            fullWidth
            className={classes.btnHover}
            style={{
              justifyContent: "left",
              color: "#000",
              textTransform: "capitalize",
            }}
            // key={i}
            // onClick={() => {
            //   section.title === "Logout"
            //     ? setOpen(true)
            //     : history.push(section.href);
            // }}
          >
            {/* <Icon color="#000" /> FSGKSNFGKL */}
          </Button>
          );
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <AppBar
        elevation={0}
        className={clsx(classes.root, className)}
        color="inherit"
        {...rest}
      >
        <Toolbar className={classes.toolbar}>
          <Hidden lgUp>
            <IconButton
              color="#fff"
              onClick={onMobileNavOpen}
              style={{ marginRight: 10, padding: "23px" }}
            >
              <SvgIcon fontSize="small">
                <MenuIcon style={{ color: "#197ab3" }} />
              </SvgIcon>
            </IconButton>
          </Hidden>
          {/* <TopBarData /> */}
          <Box style={{ marginLeft: "8rem" }}></Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "6rem",
            }}
          >
            <Box className={classes.FiterBox}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box>
                 
                    <Button className={classes.blueButton} onClick={BtnHandler}>
                      {address ? address.substring(0, 10)+"..." : "Connect wallet"}
                    </Button>            &nbsp;&nbsp;

                </Box>
                <Box>
                </Box>
                <div className={classes.Horizontal}></div>
                {/* <Dialog
                style={{ width: "200px !important" }}
                classes={{ paper: classes.desktopDrawer1 }}
                open={rightBar}
                onClose={() => {
                  setRightBar(false);
                }}
              >
                <Typography style={{ color: "#000" }}>Notification</Typography>
              </Dialog> */}
              </Box>
            </Box>
            &nbsp;&nbsp;
            <RightSideDrawer profileImage={profile?.userProfilePic}/>
          </Box>
        </Toolbar>
      </AppBar>

      <Modal open={open} onClose={handleCloseNoti}>
        <Box className={classes.root}>
          <Container maxWidth="sm">
            <Box></Box>
            <Paper className={classes.modal}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  padding: "0px 0px 0px 257px",
                }}
              >
                <Button size="small" className={classes.btnmodal}>
                  Clear all
                </Button>
                <Button
                  size="small"
                  className={classes.btnmodal}
                  onClick={handleCloseNoti}
                >
                  Close
                </Button>
              </Box>
              <Box>
                {Data.map((value) => {
                  return (
                    <>
                      <Box className={classes.box}>
                        <Box ml={2}>
                          {/* <img src="images/notification.png" alt="" className="" /> */}
                        </Box>

                        <Box style={{ paddingLeft: "10px" }}>
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "12px",
                              lineHeight: "15px",
                              color: "#4D4D4D",
                              marginTop: "-1%",
                            }}
                          > <img src="images/crypto.png" alt="" className="" style={{paddingTop:"10px"}} />
                            {value.text}
                          </Typography>
                          <Typography className={classes.typo2}>
                            {value.text1}
                          </Typography>
                        </Box>
                      </Box>
                      <hr
                        style={{
                          marginTop: "1%",
                          border: "1px solid #D9D9D9",
                          // width: "1146px",
                          // height: "0px",
                          // left: "423px",
                          // top: "289px",
                        }}
                      />
                    </>
                  );
                })}
              </Box>
            </Paper>
          </Container>
        </Box>
      </Modal>
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

