import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout/index";
import DashboardLayout from "src/layouts/DashboardLayout1";
import ForgetasswordLink from "src/views/Auth/forget-password-link";
import Login from "src/views/Auth/forget-password-link/OtpVerify";
import Reset from "src/views/Auth/reset-password/index.js";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/Auth/LogIn")),
  },

  {
    exact: true,
    path: "/dash",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/dash")),
  },
  {
    exact: true,
    path: "/userManagement",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/index")),
  },
  {
    exact: true,
    path: "/reset",
    layout: Reset,
    component: lazy(() => import("src/views/Auth/reset-password/index.js")),
  },
  {
    exact: true,
    path: "/change-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/SetPassword")),
  },
  {
    exact: true,
    path: "/content",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/contentManagement/content.js")),
  },
  {
    exact: true,
    path: "/view-content-terms",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/contentManagement/viewContent.js")),
  },
  {
    exact: true,
    path: "/viewprivacypolicy",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/contentManagement/viewPolicy.js")),
  },
  {
    exact: true,
    path: "/view-Profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/viewProfile")),
  },
  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/profile")),
  },
  {
    exact: true,
    path: "/editProfile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/editProfile")),
  },
  {
    exact: true,
    path: "/view-content",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/contentManagement/viewContent.js")),
  },
  {
    exact: true,
    path: "/edit-content-terms",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/contentManagement/editContent.js")),
  },
  {
    exact: true,
    path: "/editPrivacy",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/contentManagement/editPrivacy.js")),
  },
  {
    exact: true,
    path: "/edit-about",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/contentManagement/editAbout")),
  },
  {
    exact: true,
    path: "/view-about",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/contentManagement/viewAbout.js")),
  },
  {
    exact: true,
    path: "/editAbout",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/contentManagement/editAbout.js")),
  },
  {
    exact: true,
    path: "/editContact",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/contentManagement/editContact.js")),
  },
  {
    exact: true,
    path: "/viewContact",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/contentManagement/viewContact.js")),
  },
  {
    exact: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/notificationManagement/notification.js")),
  },
  {
    exact: true,
    path: "/notification-details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/notificationManagement/notificationDetails.js")),
  },
  {
    exact: true,
    path: "/edit-notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/notificationManagement/editNotification.js")),
  },
  {
    exact: true,
    path: "/add-notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/notificationManagement/addNotification")),
  },
  {
    exact: true,
    path: "/transacation",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/transactionHistory/transactionHistory")),
  },
  {
    exact: true,
    path: "/transaction-details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/transactionHistory/transactionDetails.js")),
  },

  {
    exact: true,
    path: "/tradeManagement",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/tradeManage")),
  },
  {
    exact: true,
    path: "/tradedetails",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/tradeDetails")),
  },
  
  {
    exact: true,
    path: "/forgot",
    layout: LoginLayout,
    component: lazy(() => import("src/views/Auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: Login,
    component: lazy(() => import("src/views/Auth/forget-password-link/OtpVerify")),
  },
 
  {
    exact: true,
    path: "/settlement",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/settlement")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
