/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import { matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  List,
  makeStyles,
  Avatar,
  Typography,
  Button,
} from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import NavItem from "src/layouts/DashboardLayout1/NavBar/NavItem1";
import { useHistory } from "react-router-dom";
import ConfirmationDialog from "src/component/Confirmation";
import {
  FaWallet,
  FaSignOutAlt,
  FaUserEdit,
  FaUser,
  FaSignInAlt,
} from "react-icons/fa";
import Cookies from 'js-cookie'



const sections = [
  // {
  //   title: "Login",
  //   href: "/login",
  //   icon: FaSignInAlt,
  // },
  {
    title: "My Profile",
    href: "/profile",
    icon: "/images/user.png",
  },
  // {
  //   title: "Disconnect Wallet",
  //   href: "",
  //   icon: "/images/walletIcon.svg",
  // },
  {
    title: "Change Password",
    href: "/change-profile",
    icon: "/images/lock.png",
  },

  {
    title: "Logout",
    href: "/dashboard",
    icon: "/images/logoutIcon.svg",
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    position: "absolute",
    right: -25,
    top: 30,
    width: "240px !important",
    padding: "12px",
    color: "#000",
    // background: theme.palette.primary.main,
  },
  avatar: {
    cursor: "pointer",
    width: "44px !important",
    height: "44px !important",
    border:"1px solid #eaebed",
    borderRadius:"100%",
    "@media (max-width: 767px)": {
      width: "44px",
      height: "44px",
    },
  },
  Name: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "right",
    letterSpacing: " 0.2px",
    color: "#FFFFFF",
    padding:"10px",
    textTransform: "capitalize"
  },
  ProfileTitle: {
    "& h6": {
      fontSize: "16px !important",
    },
  },
  btnHover: {
    "& button": {
      "& :hover": {
        backgroundColor: "#F38500",
        color: "#000",
      },
    },
  },
  avatarBig: {
    cursor: "pointer",
    width: 150,
    height: 150,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,


    
  },
  //   .MuiButton-root:hover
}));

const NavBar = (props) => {
  const {profileImage, disconnectWallet} = props;
  const classes = useStyles();
  const [rightBar, setRightBar] = useState(false);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const name = localStorage.getItem('name');
  const confirmationHandler = () => {
    console.log("fffffff")
    Cookies.remove('auth')
    history.push("/login");
            

  };
const closeMenu = () => {setRightBar(!rightBar)}
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"Logout"}
          desc={"Do you want to logout ?"}
          confirmationHandler={confirmationHandler}
          style={{ color: "#fff" }}
        />
      )}
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        className="walletbox"
      >
        <Box pt={1}>
          {sections.map((section, i) => {
           
            return (
              <Button
                fullWidth
                className={classes.btnHover}
                style={{
                  justifyContent: "left",
                  color: "#000",
                  textTransform: "capitalize",
                }}
                key={i}
                onClick={() => {
                  section.title === "Logout"
                    ? setOpen(true)
                   : section.title === "Disconnect Wallet" ? disconnectWallet(closeMenu) : history.push(section.href)
                }}
              >
                <img src={section.icon}/> &nbsp;&nbsp;{section.title}
              </Button>
            );
          })}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Typography>Admin</Typography> &nbsp;
        {/* <p  className={classes.Name}>{name ? name : "user"} </p> */}
        <Avatar
          src={profileImage ? profileImage : "/images/defaultavatar.jpeg"}
          className={classes.avatar}
          onClick={() => {
            setRightBar(!rightBar);
          }}
        />
      </Box>
      <Dialog
        style={{ width: "200px !important" }}
        classes={{ paper: classes.desktopDrawer }}
        open={rightBar}
        onClose={() => {
          setRightBar(false);
        }}
      >
        {content}
      </Dialog>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
